<template>
    <section style="height: 100%;">
        <div class="flex align-center" style="padding: 15px 0;">
            <img :src="bs" alt=""/>
            <span style="color:#FFFFFF;font-size: 18px;font-weight: bold;margin-left: 10px">小程序访问量</span>
        </div>
        <div style="height: 73%;border: 1px #2BA3FF dashed">
            <div id="visitsECharts" style="height: 100%;width: 99%;"></div>
        </div>
    </section>
</template>

<script>
import * as echarts from "echarts";
import {getWxVisitsDataList} from '@/api/data-screen';
import {timeFormat} from "../../../../utils/custom";
const bs = require("@images/icon/bs.png");


export default {
    name: "wxapp-visits",
    data() {
        return {
            bs,
            ECharts: null,
            options: {
                grid: {x: 40, y: 30, x2: 30, y2: 30},
                legend: {},
                tooltip: {trigger: 'axis', axisPointer: {type: 'shadow'}},
                xAxis: {
                    type: 'category',
                    axisLabel: {textStyle: {fontSize: 10, color: '#33A6FF',},},
                },
                yAxis: {
                    type: 'value',
                    splitLine: {show: true, lineStyle: {type: 'dashed', color: '#2F5594'}},
                    axisLabel: {textStyle: {fontSize: 10, color: '#33A6FF',},},
                },
                series: [
                    {
                        type: 'bar',
                        barWidth: 8,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {offset: 0, color: '#308AFF'}, {offset: 1, color: '#FFFFFF'},
                            ]),
                            borderRadius: [2, 2, 0, 0],
                        },
                        tooltip: {
                            valueFormatter(value) {
                                return '访问量 ' + value + ' 次';
                            }
                        },
                    }
                ],
            }
        };
    },
    components: {},
    methods: {
        eChartsInit(eChartsName, eChartsOptions) {
            let {...options} = eChartsOptions;
            this[eChartsName] = echarts.init(document.getElementById(eChartsName));
            this[eChartsName].setOption(options, true);
            window.addEventListener("resize", () => {
                this[eChartsName].resize();
            });
        },
        formatOption(eChartsOptions, xData, data1) {
            eChartsOptions.xAxis.data = xData;
            eChartsOptions.series[0].data = data1;
            return eChartsOptions;
        },
        getWxVisitsDataList() {
            const that = this;
            const {options} = that;
            getWxVisitsDataList().then((res) => {
                // console.log(res);
                let {list} = res;
                const dayAyy = [];
                const numberArr = [];
                list.map((item)=>{
                    const {ref_date,visit_pv} = item;
                    numberArr.push(visit_pv);
                    const year = ref_date.substring(0,4);
                    const month = ref_date.substring(4,6);
                    const day = ref_date.substring(6,8);
                    dayAyy.push(year+'-'+month+'-'+day);
                });

                const eChartsOptions = that.formatOption(options, dayAyy, numberArr);
                that.eChartsInit("visitsECharts", eChartsOptions);
            });
        }
    },
    mounted() {
        this.getWxVisitsDataList();

    }
}
</script>

<style scoped>

</style>